import router from './router'
import store from './store'
import filters from './helpers/filters'

import { createApp } from 'vue'
import App from './App.vue'

let bootstrap = require('bootstrap')
import 'bootstrap/dist/css/bootstrap.min.css'
import '../src/assets/styles.scss';
import '../src/assets/css/responsive.css';
import jQuery from "jquery"
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import CKEditor from '@ckeditor/ckeditor5-vue';


window.$ = window.jQuery = require('jquery');
const app = createApp(App)
            .use(ToastPlugin,{ position: 'top-right', duration: 4000, dismissible:true})
            .use(router)
            .use(store)
            .use(jQuery)
            .use(CKEditor)

app.config.globalProperties.$filters = filters;
            
app.mount('#app')
// global.jQuery = require('jquery');
// var $ = global.jQuery;
// window.$ = $;

window.axios = require('axios');
axios.defaults.baseURL = 'https://fantasy.zplay1.in/api/'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Authorization'] ='Bearer ' + localStorage.getItem('_token');


// those lines will catch your 401 status returend requests 
axios.interceptors.response.use(function (response){
    return response;
},function (error){
    if(error.response.status == 401)
    {
        localStorage.removeItem('_token'); 
        router.push('/login') 
        return error.response;
    } else {
        return error.response;
    }
})