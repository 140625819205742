// import moment from 'moment';
// import 'moment-timezone';
const filters = {
    toDateTimeFormat(timestamp) {
        let date = new Date(timestamp);
        return date.toDateString() + ' ' + (date.toLocaleString([], {
            hour: 'numeric', minute: 'numeric', hour12: true
        })).toUpperCase()
    }
//   displayDateFormat(datetime) {
//     return moment.unix(datetime).format('ddd, D MMM');
//   },
//   displayTimeFormat(datetime) {
//     return moment.unix(datetime).format('hh:mm A');
//   },
//   dateTimeFormat(datetime) {
//     return moment.unix(datetime).format('DD/MM/YYYY hh:mm A');
//   },
//   localDateTimeFormat(datetime) {
//     return moment.utc(datetime).tz('Asia/Kolkata').format('DD MMM YYYY h:mmA');
//   },
}
export default filters;
