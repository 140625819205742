<template>
  <div>
    <loader v-if="isLoader" />
		<navigation/>

		<section class="theme-warpper">
			<div class="container-fluid">
				<div class="row">
					<sidebar v-if="$route.path!=='/login'"/>
					<router-view/>
				</div>
			</div>
		</section>
		
		<main-footer />
	</div>
</template>

<script>

import Navigation from '@/components/teams/navigation.vue';
import Sidebar from '@/components/sidebar.vue';
import MainFooter from '@/components/teams/main-footer.vue';
import Loader from '@/components/Loader.vue'
import { mapState } from 'vuex';

export default {
  components: {
    'navigation': Navigation,
    'main-footer': MainFooter,
    'sidebar': Sidebar,
    'loader': Loader
  },
  mounted(){
    if(!localStorage.getItem('_token') && localStorage.getItem('_token') == null){
      this.$router.push('/login')
    }
  },
  data(){
    return {

    }
  },
  computed : {
    ...mapState([
      'isLoader'
    ])
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
