<template>
    <header class="header-warpper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-6">
                    <router-link to="/" class="navbar-brand"><img src="@/assets/images/logo.png" alt="logo"></router-link>
                </div>
                <div class="col-6">
                    <div class="my-account-dd" v-if="$route.path !== '/login'">
                        <div class="dropdown dropstart">
                            <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <span>My Account</span> <i class="bi bi-chevron-down"></i>
                            </button>
                            <ul class="dropdown-menu user-img-sec-list" aria-labelledby="dropdownMenuButton1">
                                <li class="setting-icon" v-if="user">
                                    <a href="#">
                                        <i class="fa fa-user"></i>
                                        <span class="user-name">{{ user.name }}({{ user.userid }})</span>
                                    </a>
                                </li>
                                <li class="setting-icon" v-if="user">
                                    <a href="#manage-global-commission-modal" data-bs-toggle="modal">
                                        <i class="fa fa-user"></i>
                                        <span class="user-name">Manage Commission</span>
                                    </a>
                                </li>
                                <li>
                                    <a @click="logout()">
                                        <i class="fa fa-sign-out"></i>
                                        <span class="log-out">Log out</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade cmn-modal add-new-team-modal" id="manage-global-commission-modal" data-bs-backdrop="static" tabindex="-1"
            aria-labelledby="exampleModalLabel" aria-hidden="true" v-if="user">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <form>
                        <div class="modal-header">
                            <h5 class="modal-title">Manage Global Commission</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="reset_form()"></button>
                        </div>
                        <div class="modal-body">
                            <ul class="error-messages" v-if="error_message">
                                <li class="text-danger" v-for="(err, index) in error_message" :key="index">
                                    {{ err }}
                                </li>
                            </ul>
                            <div class="cmn-form">
                                <div class="mb-3">
                                    <label class="form-label">Bet With Team:</label>
                                    <input type="number" class="form-control" v-model="commission.team">
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Bet With Run:</label>
                                    <input type="number" class="form-control" v-model="commission.run">
                                </div>
                                <div class="mb-3">
                                    <label class="form-label">Bet With Ball:</label>
                                    <input type="number" class="form-control" v-model="commission.ball">
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="reset_form()">Cancel</button>
                            <button type="button" class="btn btn-primary" @click="save_commission()">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { Modal } from "bootstrap"
export default {
    data() {
        return {
            user:{},
            commission: {
                team: null,
                run: null,
                ball: null,
            },
            old_commission: {
                team: null,
                run: null,
                ball: null,
            },
            commission_Modal: '',
        }
    },
    created(){
        this.user=JSON.parse(localStorage.getItem('authData'))
        this.old_commission=JSON.parse(localStorage.getItem('global_commission'))
        this.commission=JSON.parse(localStorage.getItem('global_commission'))
        if(!this.old_commission) {
            this.old_commission = { team: null, run: null, ball: null }
            this.commission = { team: null, run: null, ball: null }
        }
    },
    mounted() {
        if(this.user) {
            this.commission_Modal = new Modal(document.getElementById('manage-global-commission-modal'), {
                keyboard: false
            });
        }
    },
    methods: {
        logout() {
            axios.post('logout').then(res => {
                localStorage.removeItem('_token');
                localStorage.removeItem('authData');
                localStorage.removeItem('global_commission');
                this.$router.push('/login')
            })
        },
        save_commission() {
            if(JSON.stringify(this.old_commission) === JSON.stringify(this.commission)) {
                this.$toast.warning("Nothing to update!");
            } else {
                this.$store.commit('is_loader', true); 
                axios.post('save-global-commission', this.commission).then(res => {
                    if (res.data.error === true) {
                        this.$toast.error(res.data.message);
                    } else {
                        this.$toast.success(res.data.message);
                        localStorage.setItem('global_commission',JSON.stringify(this.commission));
                        this.old_commission = Object.assign({}, this.commission)
                        this.commission_Modal.hide()
                    }
                    this.$store.commit('is_loader', false);
                }).catch(e => {
                    console.error(e)
                })
            }
        },
        reset_form() {
            this.commission=JSON.parse(localStorage.getItem('global_commission'))
            if(!this.commission) {
                this.commission = { team: null, run: null, ball: null }
            }
        }
    }
}
</script>
<style scoped>
.user-name{
    margin-left: 3px;
}
.log-out{
    cursor: pointer;
    margin-left: 3px;
}

</style>

