import { createStore } from "vuex";

export default createStore({
    state: {
        teamId: null,
        edit: [],
        change: 0,
        leagues: [],
        match_teams: [],
        isLoader: false
    },
    getters: {
    },
    mutations: {
        team_Id(state, payload) {
            state.teamId = payload
        },
        data_Edit(state, payload) {
            state.edit = payload
        },
        all_Teams(state, payload) {
            state.change = payload
        },
        all_Leagues(state, payload) {
            state.leagues = payload
        },
        set_match_teams(state, payload) {
            state.match_teams = payload
        },
        is_loader(state, payload) {
            state.isLoader = payload
        }

    },
    actions: {
    }
});
