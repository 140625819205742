<template>
	<div class="col-sm-12 col-md-4 col-lg-2">
		<div class="sidebar-details">
			<div class="sidebar sidebar-offcanvas" id="sidebar">
				<ul class="details-sec">

					<li class="details-item" :class="host == 'teams' ? 'active' : ''" @click="setActive('teams')">
						<router-link to="/teams" class="details-link">
							<img src="@/assets/images/teams-icon.svg" alt="search-icon">
							<span class="menu-title">Teams</span>
						</router-link>
					</li>

					<li class="details-item" :class="host == 'players' ? 'active' : ''" @click="setActive('players')">
						<router-link to="/players" class="details-link">
							<img src="@/assets/images/Player-icon.svg" alt="Player-icon">
							<span class="menu-title">Players</span>
						</router-link>
					</li>

					<li class="details-item" :class="host == 'catagories' ? 'active' : ''" @click="setActive('catagories')">
						<router-link to="/catagories" class="details-link">
							<img src="@/assets/images/categories-icon.svg" alt="Player-icon">
							<span class="menu-title">Catagories</span>
						</router-link>
					</li>

					<li class="details-item" :class="host == 'leagues' ? 'active' : ''" @click="setActive('leagues')">
						<router-link to="/leagues" class="details-link">
							<img src="@/assets/images/League-icon.svg" alt="Player-icon">
							<span class="menu-title">League</span>
						</router-link>
					</li>

					<li class="details-item" :class="host.includes('match') ? 'active' : ''" @click="setActive('matches')">
						<router-link to="/matches" class="details-link">
							<img src="@/assets/images/Matches-icon.svg" alt="Matches-icon">
							<span class="menu-title">Matches</span>
						</router-link>
					</li>

					<li class="details-item" :class="host == 'markets' ? 'active' : ''" @click="setActive('markets')">
						<router-link to="/markets" class="details-link">
							<img src="@/assets/images/Result-icon.svg" alt="Matches-icon">
							<span class="menu-title">Markets</span>
						</router-link>
					</li>
					<li class="details-item" :class="host == 'operators' ? 'active' : ''" @click="setActive('operators')">
						<router-link to="/operators" class="details-link">
							<img src="@/assets/images/Donwline-icon.svg" alt="Matches-icon">
							<span class="menu-title">Operators</span>
						</router-link>
					</li>

					<li class="details-item" :class="host == 'banners' ? 'active' : ''" @click="setActive('banners')">
						<router-link to="/banners" class="details-link">
							<img src="@/assets/images/banner-img.svg" alt="Matches-icon">
							<span class="menu-title">Banner Images</span>
						</router-link>
					</li>

					<li class="details-item" :class="host == 'tutorial-videos' ? 'active' : ''" @click="setActive('tutorial-videos')">
						<router-link to="/tutorial-videos" class="details-link">
							<img src="@/assets/images/tutorial-video.svg" alt="Matches-icon">
							<span class="menu-title">Tutorial videos</span>
						</router-link>
					</li>

					<li class="details-item" :class="host == 'tutorial-steps' ? 'active' : ''" @click="setActive('tutorial-steps')">
						<router-link to="/tutorial-steps" class="details-link">
							<img src="@/assets/images/tutorial-step.svg" alt="Matches-icon">
							<span class="menu-title">Tutorial Steps</span>
						</router-link>
					</li>

					<li class="details-item" :class="host == 'rules' ? 'active' : ''" @click="setActive('rules')">
						<router-link to="/rules" class="details-link">
							<img src="@/assets/images/rule.svg" alt="Matches-icon">
							<span class="menu-title">Rules</span>
						</router-link>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	data() {
		return {
			active_el: 0,
			host: ''
		}
	},
	methods: {
		setActive(categoryName) {
			this.host = categoryName
		}
	},
	mounted() {
		var url = window.location.pathname 
		this.host = (url.split('/')[1]).split('-')[0]
	}

};

</script>

