import { createRouter, createWebHistory } from 'vue-router'

const routes = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: () => import('@/components/Home.vue')
        },
        {
            path: '/login',
            component: () => import('@/components/auth/login.vue')
        },
        {
            path: '/teams',
            component: () => import('@/components/teams/index.vue')
        },
        {
            path: '/players',
            component: () => import('@/components/player/index.vue')
        },
        {
            path: '/catagories',
            component: () => import('@/components/catagories/index.vue')
        },
        {
            path: '/leagues',
            component: () => import('@/components/leage/index.vue')
        },
        {
            path: '/matches',
            component: () => import('@/components/matches/index.vue')
        },        
        {
            props: true,
            path: '/match-details/:match_id',
            component: () => import('@/components/matches/details.vue')
        },  
        {
            path: '/markets',
            component: () => import('@/components/markets/index.vue')
        },        
        {
            props: true,
            path: '/market-details/:match_id',
            component: () => import('@/components/markets/details.vue')
        },        
        {
            path: '/operators',
            component: () => import('@/components/operators/index.vue')
        },        

        // FRONTEND ASSETS
        {
            path: '/banners',
            component: () => import('@/components/banner/index.vue')
        },
        {
            path: '/tutorial-videos',
            component: () => import('@/components/tutorialVideos/index.vue')
        },
        {
            path: '/tutorial-steps',
            component: () => import('@/components/tutorialSteps/index.vue')
        },
        {
            path: '/rules',
            component: () => import('@/components/rules/index.vue')
        },

    ],
})

export default routes
